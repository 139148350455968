.sidebar-wrapper {
  .sidebar {
    position: absolute;
    left: 0px;
    transform: translateX(-100%);
    @media screen and (min-width: 768px) {
      height: calc(100% - 72px);
    }
  }
  & > .overlay {
    display: none;
  }
  &.show {
    display: block;
    .sidebar {
      animation: show 0.5s;
      animation-fill-mode: forwards;
    }
    .overlay {
      display: block;
      @media screen and (max-width: 767px) {
        opacity: 0.2;
        flex: 0 0 100%;
      }
    }
  }
}

@keyframes show {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
