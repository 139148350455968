@import 'assets/scss/main.scss';

.map_container {
  font-family: sans-serif;
  touch-action: none;

  position: relative;
  width: 100%;

  background: $black_color;

  .map-connect-wallet {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      background: #151317;
      border: 1px solid #38333e;
      border-radius: 18px;

      padding: 10px;
      text-align: center;

      color: white;
    }
  }
}
