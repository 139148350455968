@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Teko:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Manrope', sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #000;
  font-size: 18px;
}
* {
  font-family: 'Manrope';
  box-sizing: border-box;
}

.font-teko,
.font-teko * {
  font-family: 'Teko';
}

.font-play,
.font-play * {
  font-family: 'Play';
}

.primary-heading {
  font-family: 'Teko';
  font-size: 100px;
  font-weight: 300;
  line-height: 80px;
  color: #fff;
  margin-top: 20px;
}
.sub-heading {
  font-family: 'Play';
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
