@import 'assets/scss/main.scss';

.map-land-selection {
  background: $text_black;
  border-radius: 28px;

  width: 230px;
  padding: 10px 0;

  position: absolute;
  left: 26px;
  top: 23px;
  &__header {
    border-bottom: 1px solid $border_grey;
    p {
      font-family: $font_play;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 200%;
      text-align: center;
      letter-spacing: 0.1em;

      color: $text_light_gray;
    }
    #map-clicked-item-content {
      margin-bottom: 12px;
      padding: 0 10px;
      p {
        font-family: $font_teko;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 43px;
        text-align: center;

        color: $text_white;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    button {
      width: calc(100% - 70px);
    }
  }
  &__body {
    padding: 10px 20px;
  }
  &__closer {
    display: none;
    position: absolute;
    background: #000000;
    border-radius: 40px 0px 0px 40px;
    width: 55px;
    height: 66px;

    bottom: 30px;
    right: 230px;

    align-items: center;
    justify-content: center;
  }
}

@media (max-width: '600px') {
  .map-land-selection {
    display: none;
    top: 0;
    right: 0;
    border-radius: 0;
    left: unset;
    height: calc(100vh - 82px);

    transition: all 1s linear 1s;

    &__closer {
      display: flex;
    }
  }
}
