@import 'assets/scss/main.scss';

.map-wrapper {
  width: 100%;
  height: calc(100vh - 82px);

  position: relative;
  .map-land-controllers {
    right: 30px;
    bottom: 30px;
    position: absolute;
    display: flex;
    flex-direction: column;
    button {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000b;
      padding: 14px 10px;
      svg {
        width: 21px;
        height: 21px;
        path {
          fill: $text_white;
        }
      }
      &:first-child {
        border-top-right-radius: 32.5px;
        border-top-left-radius: 32.5px;
        border-bottom: 1px solid $text_white;
      }
      &:last-child {
        border-bottom-right-radius: 32.5px;
        border-bottom-left-radius: 32.5px;
        border-top: 1px solid $text_white;
      }
      &:hover {
        background: #000e;
      }
    }
  }
  .map-land-minter {
    display: none;
  }
}
#map {
  width: 100%;
  height: calc(100vh - 82px);
}
#map .item {
  &:not(.disabled) {
    cursor: pointer;
  }
  stroke-width: 0.8;
}
#map .district {
  cursor: pointer;
  stroke-width: 20px;
  fill-opacity: 0.2;
}
#map:not(.mobile) .district:hover {
  fill: #fff;
  fill-opacity: 0.4;
  transition: fill 0.1s ease-in-out;
}
#map.mobile .district {
  pointer-events: none;
}
#map .item.selected {
  stroke: #fff;
  stroke-width: 1.8px;
}
#map:not(.mobile) .item.activated {
  &:not(.disabled) {
    fill: red;
  }
}
#map .item:hover {
  stroke: #ffecd2;
  stroke-width: 1.3px;
}

.group-info {
  display: flex;
  align-items: center;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  .name {
    margin: 0 10px;
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 200%;
    color: $text_grey;

    &:hover {
      text-decoration: underline;
    }
  }
}

.preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  border: 1px solid black;
  background-color: #fff;
}

.item.disabled {
  fill: gray;
  /* stroke: gray; */
  stroke-width: 2;
  /* pointer-events: none; */
}

.map-panel {
  display: flex;
  flex-direction: column;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    margin-bottom: 4px;
  }
}

/*.zoom-level-one {*/
/*outline: 10px solid black;*/
/*}*/
/*.zoom-level-two {*/
/*outline: 10px solid purple;*/
/*}*/

@media (max-width: '600px') {
  .map-wrapper {
    .map-land-controllers {
      left: 30px;
    }
    .map-land-minter {
      display: flex;
      align-items: center;
      gap: 10px;

      padding: 16px;

      background: #000;
      border-radius: 40px 0px 0px 40px;
      height: 66px;

      position: absolute;
      right: 0;
      bottom: 30px;

      h1 {
        color: $text_white;
        font-family: $font_teko;
        font-style: normal;
        font-weight: normal;
        font-size: 45px;
        line-height: 45px;
      }
    }
  }
}
