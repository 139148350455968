@import 'assets/scss/main.scss';

.land-tooltip {
  position: absolute;
  background: $black_color;
  border-radius: 20px;
  top: 100px;
  left: 100px;
  width: 320px;
  height: 85px;
  display: none;
  &__container {
    padding: 15px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    width: 100%;
    height: calc(100% - 15px);
    box-sizing: border-box;
    overflow: hidden;
    p {
      color: $text_white;
      font-family: $font_play;
      font-style: normal;
      font-weight: bold;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &::after {
      width: 18px;
      height: 18px;
      position: absolute;
      content: '';
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #040404;
    }
  }

  &__minted {
    font-size: 10px;
    line-height: 8px;
    text-align: center;
    letter-spacing: 0.3em;
  }

  &__owner {
    font-size: 12px;
  }

  &__land {
    font-size: 22px;
  }
}
