$border_grey: #38333e;
$border_white: #ffffff;

$text_orange: #ff850b;
$text_yellow: #ffd348;
$text_purple: #e23eff;
$text_lightPurple: #c397f7;
$text_blue: #0060f9;
$text_green: #85f769;
$text_grey: #4b4750;
$text_light_gray: #504a58;
$text_dark_grey: #151317;
$text_white: #ffffff;
$text_black: #000000;

$black_color: #000000;

$bg_orange: #ff850b;
$bg_yellow: #ffd348;
$bg_purple: #e23eff;
$bg_blue: #0060f9;
$bg_green: #85f769;
$bg_dark: #151317;
$bg_black: #000000;
$bg_grey: #1f1c22;

.text-orange {
  color: $text_orange;
  svg {
    path {
      fill: $text_orange;
      stroke: $text_orange;
    }
  }
}

.text-yellow {
  color: $text_yellow;
  svg {
    path {
      fill: $text_yellow;
      stroke: $text_yellow;
    }
  }
}

.text-purple {
  color: $text_purple;
  svg {
    path {
      fill: $text_purple;
      stroke: $text_purple;
    }
  }
}

.text-lightPurple {
  color: $text_lightPurple;
  svg {
    path {
      fill: $text_lightPurple;
      stroke: $text_lightPurple;
    }
  }
}

.text-blue {
  color: $text_blue;
  svg {
    path {
      fill: $text_blue;
      stroke: $text_blue;
    }
  }
}

.text-green {
  color: $text_green;
  svg {
    path {
      fill: $text_green;
      stroke: $text_green;
    }
  }
}

.text-grey {
  color: $text_grey;
  svg {
    path {
      fill: $text_grey;
      stroke: $text_grey;
    }
  }
}
