@import 'assets/scss/main.scss';

.new-land-tooltip {
  position: fixed;
  background: $black_color;
  border-radius: 20px;
  top: 100px;
  left: 100px;
  height: 42px;
  width: 330px;
  display: none;
  z-index: 1000;

  &__container {
    padding: 5px 5px 5px 5px;
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    justify-content: center;
    gap: 3px;
    // height: calc(100% - 15px);
    box-sizing: border-box;
    overflow: hidden;
    p {
      padding: 0 10px;

      color: $text_white;
      font-family: $font_play;
      font-style: normal;
      font-weight: bold;
      text-align: center;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &::after {
      width: 18px;
      height: 18px;
      position: absolute;
      content: '';
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #040404;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    cursor: pointer;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      height: 14px;
      path {
        fill: $text_white;
      }
    }

    &--orange {
      background: $bg_orange;
    }
    &--warning {
      background: $bg_yellow;
    }
    &--info {
      background: $bg_blue;
    }
    &--success {
      background: $bg_green;
    }
    &--secondary {
      background: $bg_purple;
    }
  }

  &__land {
    font-size: 22px;
  }

  &:hover {
    display: block;
  }

  &.disabled {
    width: 100px;
    height: 18px;
    background: $bg_grey;

    & > div {
      padding: 4px;
    }

    p {
      font-size: 9px;
      line-height: 10px;
    }
  }
}
