@import 'assets/scss/main.scss';

.modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 900;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    padding: 5px;

    max-width: 360px;
    border-radius: 20px;

    background-color: $bg_dark;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;

      position: relative;

      width: 100%;

      border: 1px solid $border_grey;
      box-sizing: border-box;
      border-radius: 18px;

      padding: 35px 20px;
    }
  }
  &-header {
    margin-bottom: 10px;

    width: 100%;
    &__description {
      margin-bottom: 10px;
      h5 {
        font-family: $font_teko;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 88.3%;

        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: $text_white;
      }
      p {
        font-family: $font_play;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        text-align: center;

        color: $text_white;
      }
    }
    &__action {
      position: absolute;
      top: 10px;
      right: 10px;

      cursor: pointer;
    }
  }
  &-body {
    width: 100%;
    min-height: 170px;
    max-height: 250px;
    box-sizing: border-box;
    background: $bg_black;
    border-radius: 5px;

    padding: 15px;
    margin-bottom: 15px;

    overflow-y: auto;
  }

  &-row {
    display: grid;
    grid-template-columns: 17px 1fr;
    align-items: center;
    gap: 5px;

    margin-bottom: 10px;

    &__text {
      font-family: $font_play;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
      color: $text_white;
    }
  }
  &-footer {
    &__description {
      margin-bottom: 30px;
      p {
        font-family: $font_play;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 90%;

        text-align: center;
        color: $text_white;

        margin-bottom: 10px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 20px;
      .form-button {
        margin-bottom: 0;
        justify-content: flex-start;
        button {
          padding: 12px;
        }
      }
    }
  }
}
