@import 'assets/scss/main.scss';

.homepage-map_container {
  font-family: sans-serif;
  touch-action: none;
  position: relative;
  margin: 16px 40px;
  background: #186586;
  border: 3px dashed #1e84af;

  .map-wrapper {
    width: 100%;
    overflow: hidden;
    height: 768px;
  }
}

.homepage-btn {
  font-family: 'Teko', sans-serif;
  letter-spacing: 0.05rem;
  width: 220px;
}

.homepage-info {
  font-family: 'Play', sans-serif;
}

.map-box {
  height: 1200px;
}
