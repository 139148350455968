.box-wrapper {
  width: 100%;
  margin-top: 100px;
  .box-container {
    &__header {
      margin-bottom: 30px;
      h1 {
        font-family: 'Teko', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 70px;
        line-height: 88.3%;

        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: #ffffff;
      }
    }
    &__content {
      display: flex;
      justify-content: center;
    }
    &__container {
      width: 463px;

      background: #151317;
      border-radius: 20px;
      border: 1px solid #38333e;
      padding: 35px;
    }
  }
}

@media (max-width: 600px) {
  .box-wrapper {
    margin-bottom: 100px;
    .box-container {
      &__container {
        width: 90%;
      }
    }
  }
}
