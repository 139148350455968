@import 'assets/scss/main.scss';

.not-found {
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: cover;

  &__container {
    margin-top: 100px;
  }

  &__header {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 250px;

    font-style: normal;
    font-weight: 500;
    font-size: 400px;
    line-height: 88.3%;

    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: $text_white;

    text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.55);
    img {
      position: absolute;
    }
  }

  h1 {
    margin: 40px 0;
    font-family: $font_play;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    text-align: center;

    color: $text_white;
  }
}
@media (max-width: 600px) {
  .not-found {
    &__header {
      zoom: 0.5;
    }
  }
}
