@import 'assets/scss/main.scss';

.landbar {
  width: 230px;
  height: calc(100vh - 82px);

  &__header {
    padding: 20px 50px;
    border-bottom: 1px solid $border_grey;
    height: 150px;
    p {
      font-family: 'Play', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 200%;

      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #c397f7;
    }
  }
  &__content {
    padding: 14px;
    height: calc(100% - 150px);

    overflow-y: auto;
  }
}
