@import 'assets/scss/main.scss';

.form-dropdown {
  margin-bottom: 14px;
  &__header {
    display: grid;
    grid-template-columns: 1fr 24px;
    align-items: center;

    margin-bottom: 10px;
    gap: 5px;
    cursor: pointer;

    &__action {
      display: grid;
      grid-template-columns: 18px 1fr;
      align-items: center;
    }

    &__arrow {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
    &__label {
      font-family: 'Teko', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      &:hover {
        text-decoration: underline;
      }
    }
    &__description {
      font-family: 'Play', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 200%;

      letter-spacing: 0.1em;
    }
  }
  &__list {
    padding-left: 20px;

    &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--viewer {
      cursor: pointer;
    }
  }
}
