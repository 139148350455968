@import 'assets/scss/main.scss';

.wrong-device {
  width: 100vw;
  height: 100vh;

  overflow: hidden;

  background: #000;

  &__container {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.41%, #000000 84.06%),
      url('./../../../assets/img/background.png');
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 40px;
  }

  &__body {
    margin: 40px;
    p {
      font-family: Play;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 39px;
      text-align: center;

      color: $text_white;
    }
  }

  &__footer {
    p {
      font-family: 'Play', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.5em;

      color: #4d4d4d;
    }
  }

  &__socials {
    gap: 30px;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    svg {
      path {
        fill: #4d4d4d;
      }
    }
  }
}
