@import 'assets/scss/main.scss';

.map-sidebar {
  width: 350px;
  height: calc(100% - 82px);

  display: flex;
  flex-direction: column;

  background: $black_color;

  &__header {
    padding: 16px 23px 0;

    display: flex;
    align-items: center;
    flex-direction: column;

    border-bottom: 1px solid $border_grey;

    &--lands {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &--land {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        color: $text_lightPurple;
        font-family: $font_play;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;

        text-align: center;
        letter-spacing: 0.1em;
      }

      h1 {
        color: $text_white;
        font-family: $font_teko;
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 79px;

        margin-bottom: 10px;
      }
    }
  }

  &__action {
    display: flex;
    padding: 8px 8px 8px 30px;

    border-bottom: 1px solid $border_grey;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-right: 5px;
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  .map-sidebar {
    width: 100%;
    position: fixed;
    top: 82px;
    transform: translateY(calc(100% - 140px));
    z-index: 1001;

    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.55);
    border-radius: 10px;
    border: 1px solid $border_grey;

    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.half-expanded {
      transform: translateY(calc(50% + 41px));
      height: calc(50% + 41px);
    }

    &.expanded {
      transform: none;
    }

    &__header {
      flex-direction: row;
      padding: 16px 4px 0;

      &--land {
        padding: 4px;
      }

      .form-button {
        white-space: nowrap;
        button {
          padding: 4px 16px;
        }
      }
    }

    &__action {
      padding: 0 0 0 30px;
    }

    .form-dropdown__header__label {
      font-size: 40px;
      font-family: Teko;
    }
  }
}
