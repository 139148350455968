@import 'assets/scss/main.scss';

@media (max-width: 767px) {
  .metamask {
    display: none !important;
  }
}

.wallet-connect {
  width: 100%;
  height: calc(100vh - 82px);
  background: $black_color;

  padding-top: 100px;
  &__container {
    h1 {
      font-family: $font_teko;
      font-style: normal;
      font-weight: normal;
      font-size: 70px;
      line-height: 88.3%;

      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      color: $text_white;

      margin-bottom: 20px;
    }
    p {
      margin: auto;

      font-family: $font_play;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: $text_white;

      margin-bottom: 50px;
      @media screen and (min-width: 768px) {
        width: 470px;
      }
    }
  }

  &__body {
    background: $text_dark_grey;
    border-radius: 20px;
    border: 1px solid $border_grey;
    padding: 35px;
    margin: auto 8px;
    @media screen and (min-width: 768px) {
      width: 465px;
      margin: auto;
    }
  }

  &__connector {
    background: $text_black;
    border: 1px solid $border_grey;
    border-radius: 40px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px 20px 10px 25px;
    margin-bottom: 20px;

    span {
      font-family: $font_play;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;

      color: $text_white;
    }

    img {
      width: 40px;
      height: 40px;
    }

    &:hover {
      border-color: $border_white;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
