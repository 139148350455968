.mobile-menu {
  height: calc(100vh - 94px);
  overflow: auto;
  z-index: 1000;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #434c62;
    opacity: 0.5;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #666d7f;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
}
.menu-item {
  span {
    position: relative;
  }
  &.menu-item-active,
  &:hover {
    color: white;
    span:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #70bdf7, #c397f7);
    }
  }
}
.btn {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  border: solid 1px transparent;
  background-clip: padding-box;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
  }
  &.btn-blue:before {
    background: linear-gradient(180deg, #71bdf7 0%, #003fff 100%);
  }
  &.btn-purple:before {
    background: linear-gradient(0deg, #6433ed 0%, #c082ff 100%);
  }
}
@media (max-width: 600px) {
  .topnav {
    .logo {
      background: none !important;
    }
  }
}
