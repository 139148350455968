@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Teko:wght@300;400;500;600;700&display=swap');

@import './colors';
@import './font';

.netvrk-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    background: #38333e;
    border-radius: 10px;
  }
}
