@import 'assets/scss/main.scss';

.form-input {
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 5px;
  }
  .form-validation-error {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #ff5252;
  }
  input {
    width: 100%;
    background: #000000;
    border: 2px solid #504a58;
    border-radius: 40px;

    padding: 20px;

    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;

    color: #ffffff;
    &:hover {
      border-color: #c9aaee;
    }
    &.validation--error {
      border-color: #ff5252;
    }
  }
}

.form-row {
  text-align: center;
  margin-bottom: 12px;
  a {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;

    color: #71bdf7;
    cursor: pointer;
  }
}

.form-button {
  margin-bottom: 22px;
  display: flex;
  justify-content: center;
  button {
    background: linear-gradient(
      0deg,
      #26187e 13.44%,
      #5d33c9 48.19%,
      #8a5cff 88.97%
    );
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25),
      inset 0px 0px 20px rgba(0, 0, 0, 0.4);
    border-radius: 30px;
    border: 1px solid #c9aaee;

    font-family: 'Teko', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.06em;

    color: #ffffff;
    padding: 10px 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    &.disabled {
      background: linear-gradient(
        0deg,
        #282828 13.44%,
        #444444 48.19%,
        #6c6c6c 88.97%
      );
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25),
        inset 0px 0px 20px rgba(0, 0, 0, 0.4);
      border-color: #484747;
      color: #9f9f9f;
    }
  }
}

.form-link {
  text-align: center;
  a {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;

    color: #ffffff;
  }
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 5px;

  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
  }
  span {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 200%;
    color: $text_grey;
  }
  &:not(.disabled):hover {
    span {
      text-decoration: underline;
    }
  }
  &--checked {
    span {
      color: $text_white;
    }
  }

  @media (max-width: 600px) {
    svg {
      width: 29px;
      height: 29px;
    }

    span {
      font-size: 25px;
      font-weight: normal;
    }
  }
}
