.notifications {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 8px solid #1f1c22;
  }
}

.header-body {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff850b;
  font-size: 34px;
  flex: 1 1 0%;
}

@media (max-width: 600px) {
  .no-bg-mobile {
    background: none !important;
  }
  .header-body {
    a {
      font-size: 17px;
      text-align: center;
    }
  }
  .wallet-address {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(50%);
  }
}
